<template>
  <div class="page-container">
    <!-- Section Title Start-->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content1 :title="$t('text.ourLawyers')"></Content1>
      </div>
    </div>
    <!-- Section Title End-->

    <!-- Section Lawyer List Start -->
    <div
      v-intersect.once="onIntersect"
      class="section-wrapper section-lawyer-list"
      :class="{ 'pt-0': isMobile }"
    >
      <div :class="{ 'content-wrapper': !isMobile }">
        <div class="lawyer-list-container">
          <div class="lawyer-slide-group-wrapper">
            <v-slide-group
              v-model="lawyerSlideGroup"
              mandatory
              :show-arrows="!isMobile"
            >
              <template v-slot:prev>
                <v-btn
                  min-width="53"
                  width="53"
                  height="53"
                  depressed
                  tile
                  :ripple="false"
                  color="primary"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>

              <template v-slot:next>
                <v-btn
                  min-width="53"
                  width="53"
                  height="53"
                  depressed
                  tile
                  :ripple="false"
                  color="primary"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>

              <v-slide-item
                v-for="(i, index) in 2"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <v-scroll-x-transition>
                  <v-card
                    v-intersect.once="onIntersectLawyerCard"
                    v-if="isIntersecting"
                    tile
                    :elevation="active ? 8 : 0"
                    class="mx-2 mx-md-5"
                    :height="isMobile ? 210 : 379"
                    :width="isMobile ? 152 : 275"
                    :style="`
                  transition-delay: 0.${2 + index}s !important;
                  transition-duration: 1s !important;`"
                    @click="toggle"
                  >
                    <v-img
                      :class="active ? '' : 'grey-scale'"
                      :content-class="active ? '' : 'dark-bg'"
                      height="100%"
                      :src="`${shared.BASE_URL}img/jpg/lawyer-${i}.jpg`"
                      style="transition: 0.2s ease-in-out"
                    >
                      <div
                        class="lawyer-image-content w-100 d-flex justify-start align-center px-3 px-md-8"
                        :class="{ active: active }"
                      >
                        <div class="surface--text text-capitalize">
                          <div
                            class="text-subtitle-2 text-md-h6 font-weight-bold"
                            :class="{ 'primary--text': active }"
                          >
                            {{ $t(`ourLawyers[1].${i}`) }}
                          </div>
                          <div class="text-caption text-md-subtitle-2">
                            {{ $t(`ourLawyers[2].${i}`) }}
                          </div>
                        </div>
                      </div></v-img
                    >
                  </v-card>
                </v-scroll-x-transition>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
    </div>

    <div class="lawyer-desc-card-wrapper">
      <v-scroll-x-transition>
        <v-card
          v-intersect.once="onIntersectLawyerDescCard"
          v-if="isIntersectingLawyerCard"
          tile
          class="pa-8 pa-md-12 lawyer-desc-card"
          min-height="500"
          min-width="95%"
          :style="`background-image: url('${shared.BASE_URL}img/png/bg-our-lawyers.png'); transition-duration: 1s !important; transition-delay: 0.8s !important;`"
        >
          <v-scroll-y-transition>
            <v-card-title
              v-if="isIntersectingLawyerDescCard"
              class="surface--text mt-8 px-0 text-body-1 text-md-h6"
              :style="`transition-delay: 1s !important; transition-duration: 1s !important;`"
              >{{ $t(`ourLawyers[1].${lawyerSlideGroup + 1}`) }}</v-card-title
            >
          </v-scroll-y-transition>
          <v-scroll-x-transition>
            <v-divider
              v-if="isIntersectingLawyerDescCard"
              class="surface my-2"
              :style="`transition-delay: 1.4s !important; transition-duration: 1s !important;`"
            ></v-divider>
          </v-scroll-x-transition>
          <v-scroll-y-transition>
            <v-card-text
              v-if="isIntersectingLawyerDescCard"
              class="greyDivider--text text-body-2 text-md-body-1 px-0"
              v-html="$t(`ourLawyers[3].${lawyerSlideGroup + 1}`)"
              :style="`transition-delay: 1.8s !important; transition-duration: 1s !important;`"
            ></v-card-text>
          </v-scroll-y-transition>
        </v-card>
      </v-scroll-x-transition>
    </div>
    <!-- Section Lawyer List End -->

    <!-- Section Contact Us Now Start -->
    <ContactUsNow></ContactUsNow>
    <!-- Section Contact Us Now End -->
  </div>
</template>

<script>
import { SHARED } from '@/constants'
import ContactUsNow from '@/components/section/ContactUsNow'

export default {
  name: 'OurLawyers',
  components: {
    ContactUsNow
  },
  data: () => ({
    shared: SHARED,
    lawyerSlideGroup: '',
    isIntersecting: false,
    isIntersectingLawyerCard: false,
    isIntersectingLawyerDescCard: false
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    onIntersectLawyerDescCard(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingLawyerDescCard = isIntersecting
      }
    },
    onIntersectLawyerCard(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingLawyerCard = isIntersecting
      }
    },
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    }
  }
}
</script>

<style lang="scss">
.section-lawyer-list {
  padding-bottom: 0 !important;
  .content-wrapper {
    width: 95% !important;
  }
  .lawyer-list-container {
    .lawyer-slide-group-wrapper {
      z-index: 3;
      max-width: 100%;
      .v-item-group.v-slide-group {
        z-index: 3;
      }
    }

    .lawyer-image-content {
      transition: 0.3s ease-in-out;
      position: absolute;
      bottom: 0;
      height: 80px;
      &.active {
        background: var(--v-tertiary-base);
        opacity: 0.9;
      }
    }
  }

  .v-slide-group {
    .v-slide-group__prev {
      position: absolute;
      top: 50%;
      z-index: 3;
    }
    .v-slide-group__next {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 3;
    }
    .v-slide-group__prev--disabled,
    .v-slide-group__next--disabled {
      .v-btn {
        background-color: var(--v-label-base) !important;
        border-color: var(--v-label-base) !important;
      }
    }
  }
}
.lawyer-desc-card-wrapper {
  padding-left: 7.5rem;
  .lawyer-desc-card {
    margin-top: -50px;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    .v-divider {
      width: 6rem;
      border-width: 1px;
    }
    .v-card__text {
      max-width: 90%;
    }
  }
}

.grey-scale {
  filter: grayscale(100%);
}
.dark-bg {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 959px) {
  .section-lawyer-list .lawyer-list-container .lawyer-image-content {
    height: 55px;
  }
  .lawyer-desc-card-wrapper {
    padding-left: 0;
  }
}
</style>
